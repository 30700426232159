@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .clip-trapezoid {
      clip-path: polygon(0 15%, 100% 7%, 100% 93%, 0 85%);
    }
    .clip-curve {
      clip-path: ellipse(100% 90% at 50% 100%);
    }
    .clip-curve-reverse {
      clip-path: ellipse(70% 100% at 50% 0%);
    }
  }
  
  
@keyframes floatUp {
  from {
    transform: translateY(80px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.float-up {
  opacity: 1 !important;
  animation: floatUp 1s ease-out forwards;
}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.animate-marquee {
  animation: marquee 100s linear infinite;
  display: inline-flex;
}

.animate-floatUp {
  animation: floatUp 0.6s ease-out forwards;
}

.animate-floatUp:nth-child(1) {
  animation-delay: 0.3s;
}

.animate-floatUp:nth-child(2) {
  animation-delay: 0.5s;
}

@keyframes zoom {
  from {
    transform: scale(90%);
    opacity: 0;
  }
  to {
    transform: scale(100%);
    opacity: 1;
  }
}

.zoom-up {
  opacity: 1 !important;
  animation: zoom 0.5s ease-out forwards;
}